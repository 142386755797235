import theme from '../default/index.js';

/**
 * This is an override theme file for the UROWEB site
 */

import Color from 'color';

const primaryColor = Color('#FE6B2E'),
    secondaryColor = Color('#65ABDC');

const color = {
    darkest: 'var(--color-navy)',
    lightest: '#fff',
    primary: primaryColor.hex(),
    'primary-light': primaryColor.lighten(0.15).hex(),
    'primary-dark': primaryColor.darken(0.25).hex(),
    secondary: secondaryColor.hex(),
    'secondary-light': '#EBF6FD',
    'secondary-dark': '#152443',

    'eau-blue': 'var(--color-secondary)',
    'light-blue': 'var(--color-secondary-light)',
    navy: 'var(--color-secondary-dark)',
};

export default {
    ...theme,
    color
};